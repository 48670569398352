import React from 'react'
import IFCA from '@ifca-root/react-component/src/assets/images/ifca-white-logo.png'

export const CopyRight = () => {
  return (
    <div className="copy-right desc m-b-12">
      <div className="m-b-4">
        <img src={IFCA} alt="CopyRight" className="ifca-logo m-l-8"/>
      </div>
      <div className="flex-space">
        <span className="flex-space m-l-8">
          © Copyright 2021 IFCA MSC Berhad.
        </span>
        <span className="m-r-8" style={{float:'right'}}>All Rights Reserved.</span>
      </div>
    </div>
  )
}