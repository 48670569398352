import { yupResolver } from '@hookform/resolvers'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { setAccessToken } from 'AccessToken'

import Logo from 'assets/images/HR-X.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  SoftwareCode,
  useLoginMutation,
} from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { LoginSchema } from 'yupValidation/yup'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import { HRClient } from 'HRClient'
import { CopyRight } from 'components/AuthLayout/Copyright'

interface ILoginForm {
  email: string
  password: string
  showPassword: boolean
  rememberMe: boolean
  product: string
}
export const Login = props => {
  const rememberme: any = localStorage.getItem('rememberme')
  localStorage.clear()
  if (rememberme !== null) {
    localStorage.setItem('rememberme', rememberme)
  }
  let history = useHistory()
  const [login, { data, loading, error }] = useLoginMutation({
    onError: error => {
      error?.graphQLErrors?.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    update: (store, { data }) => {
      if (!data) {
        return null
      }

      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data?.login?.user as any,
        },
      })
    },
    onCompleted: data => {
      if (data && data.login.accessToken) {
        setAccessToken(data.login.accessToken)
      }

      if (rememberMe) {
        let rememberme = {
          username: getValues('email'),
          password: getValues('password'),
        }
        localStorage.setItem('rememberme', JSON.stringify(rememberme))
      } else {
        localStorage.removeItem('rememberme')
      }
      history?.push('/')
    },
    client: HRClient as any,
  })

  const [values, setValues] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(
    rememberme !== null ? true : false
  )

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/profile')
  }
  const MESSAGE = 'Password Changed Successfully'

  const handleClickShowPassword = () => {
    setValues(!values)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleCheckRememberMe = (checked: boolean) => {
    setRememberMe(checked)
  }

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control, getValues, reset } = useForm<
    ILoginForm
  >({
    defaultValues: {
      email: rememberme !== null ? JSON.parse(rememberme)?.username : '',
      password: rememberme !== null ? JSON.parse(rememberme)?.password : '',
    },
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = data => {
    login({
      variables: {
        loginId: data?.email,
        softwareCode: SoftwareCode.Hr,
        password: encryptMessage(data?.password),
      },
    })
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Login Error',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Mobile No. / Email"
              control={control}
              ref={register}
            />
            <Grid container justify="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                label="Password "
                type={values ? 'text' : 'password'}
                value={getValues('password')}
                //   onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                ref={register}
              />
            </Grid>

            <div className="other-auth">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={e => handleCheckRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                ref={register}
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
              >
                Forgot Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              // onClick={() => history.push('/')}
            >
              Login
            </Button>
          </div>
        </form>
        <CopyRight/>
      </AuthLayout>
    </>
  )
}
